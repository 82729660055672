<template>
  <nuxt-page />
</template>

<script lang="ts" setup>
import { useHead } from '@unhead/vue';

import { definePageMeta } from '@/.nuxt/imports';
import { KAKAO_POSTAL_CODE_API } from '@/constants/common.const';
import { DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY } from '@/constants/national-list.const';
import { MenuAuthorityIds } from '@/enums/common.enum';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useNoticeStore } from '@/stores/notice.store';

definePageMeta({
  menuAuthId: MenuAuthorityIds.BIMS_BANK_SETTING
});

useHead({
  script: [{ src: KAKAO_POSTAL_CODE_API }]
});

const businessBankStore = useBusinessBankStore();
const { setDefaultCountryOption } = businessBankStore;

setDefaultCountryOption(DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY);

const noticeStore = useNoticeStore();

const { fetchLatestNotice } = noticeStore;

fetchLatestNotice();
</script>
